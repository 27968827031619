<template>
  <div class="classify-config-form-container">
    <form-panel
      ref="form"
      :form="form"
      :queryUrl="queryUrl"
      :submitUrl="submitUrl"
      :submitBefore="submitBefore"
      submitContentType="application/x-www-form-urlencoded;charset=UTF-8"
      @update="update"
    >
      <col2-detail>
        <col2-block title="基本信息">
          <el-form-item :label="superNameLabel">
            <div class="" v-text="superName"></div>
          </el-form-item>
          <el-form-item prop="name" :label="nameLabel" :rules="[{ required: true, message: nameLabel+'不能为空', trigger: 'blur' }]">
            <v-input v-model="form.name" />
          </el-form-item>
          <el-form-item prop="code" label="分类编码" :rules="[{ required: true, message: '分类编码不能为空', trigger: 'blur' }]">
            <v-input v-model="form.code" />
          </el-form-item>
          <el-form-item label="备注">
            <v-textarea v-model="form.remarks"></v-textarea>
          </el-form-item>
        </col2-block>
      </col2-detail>
    </form-panel>
  </div>
</template>
<script>
import { addURL, editURL, queryDetailURL } from './api'
import { Col2Block, Col2Detail } from '@/components/bussiness'

export default {
  name: 'classifyConfigForm',
  components: {
    Col2Block,
    Col2Detail
  },
  data () {
    return {
      queryUrl: queryDetailURL,
      submitUrl: addURL,
      form: {
        id: undefined,
        name: undefined,
        code: undefined,
        remarks: '',
        superId: undefined,
        codeLevel: '',
        dataObject: undefined
      },
      detail: {},
      superName: ''
    }
  },
  computed: {
    isEdit () {
      return Boolean(this.$route.query.id)
    },
    superNameLabel () {
      if (this.isEdit) {
        console.log(this.isEdit)
        return '上级分类'
      } else {
        return '分类名称'
      }
    },
    nameLabel () {
      if (this.isEdit) {
        return '分类名称'
      } else {
        return '子类名称'
      }
    }
  },
  mounted () {
    if (this.isEdit) {
      this.$refs.form.getData({ id: this.$route.query.id })
      this.$setBreadcrumb('编辑')
      this.submitUrl = editURL
    } else {
      this.superName = this.$route.query.superName
      this.form.superId = this.$route.query.superId
      this.form.codeLevel = Number(this.$route.query.codeLevel) + 1
      this.$setBreadcrumb('新增')
      this.submitUrl = addURL
    }
  },
  methods: {
    update (data) {
      let keys = ['code', 'id', 'name', 'remarks']
      keys.forEach(key => { this.form[key] = data[key] })
      this.superName = data.superName
    },
    submitBefore (data) {
      for (let key in data) {
        if (data[key] === '' || data[key] === undefined) delete data[key]
      }
      data.dataObject = data.name
      return true
    }
  }
}
</script>
<style lang="scss" scoped>

</style>
