var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "classify-config-form-container" },
    [
      _c(
        "form-panel",
        {
          ref: "form",
          attrs: {
            form: _vm.form,
            queryUrl: _vm.queryUrl,
            submitUrl: _vm.submitUrl,
            submitBefore: _vm.submitBefore,
            submitContentType:
              "application/x-www-form-urlencoded;charset=UTF-8",
          },
          on: { update: _vm.update },
        },
        [
          _c(
            "col2-detail",
            [
              _c(
                "col2-block",
                { attrs: { title: "基本信息" } },
                [
                  _c("el-form-item", { attrs: { label: _vm.superNameLabel } }, [
                    _c("div", {
                      domProps: { textContent: _vm._s(_vm.superName) },
                    }),
                  ]),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        prop: "name",
                        label: _vm.nameLabel,
                        rules: [
                          {
                            required: true,
                            message: _vm.nameLabel + "不能为空",
                            trigger: "blur",
                          },
                        ],
                      },
                    },
                    [
                      _c("v-input", {
                        model: {
                          value: _vm.form.name,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "name", $$v)
                          },
                          expression: "form.name",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        prop: "code",
                        label: "分类编码",
                        rules: [
                          {
                            required: true,
                            message: "分类编码不能为空",
                            trigger: "blur",
                          },
                        ],
                      },
                    },
                    [
                      _c("v-input", {
                        model: {
                          value: _vm.form.code,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "code", $$v)
                          },
                          expression: "form.code",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "备注" } },
                    [
                      _c("v-textarea", {
                        model: {
                          value: _vm.form.remarks,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "remarks", $$v)
                          },
                          expression: "form.remarks",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }